<template>
  <b-overlay
    id="overlay-background"
    :show="loadingSubmit"
    :variant="'light'"
    :opacity="'0.86'"
    :blur="'2px'"
    rounded="sm"
  >
    <b-card class="mt-0 pt-0">
      <validation-observer
        ref="accountRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2 ml-0 pl-0"
          >
            <h3
              class="mb-1 title-tab color-text-dark"
              style="font-weight: 700; color: #181E38; font-size: 28px;"
            >
              Login & Password
            </h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col
                md="12"
                class="pl-0"
              >
                <b-form-group
                  label-for="email"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Email</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="usuarioData.email"
                      placeholder="Email"
                      :state="errors.length > 0 ? false:null"
                      style="height: 52px;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                class="pl-0"
              >
                <!-- Password -->
                <b-form-group
                  label-for="password"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Password</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model="password"
                        :type="password1FieldType"
                        placeholder="Password"
                        :state="errors.length > 0 ? false:null"
                        required
                        trim
                        style="height: 52px;"
                      />
                      <b-input-group-append
                        is-text
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                        style="border: 1px solid #d8d6de; border-radius: 0px 5px 5px 0px;"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                class="pl-0"
              >
                <!-- Confirm Password -->
                <b-form-group
                  label-for="password confirm"
                >
                  <template v-slot:label>
                    <span
                      class=""
                      style="color: #656B85; font-size: 16px;"
                    >Confirm Password</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    :rules="password ? 'required|confirmed:Password' : 'confirmed:Password'"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model="passwordConfirm"
                        :state="errors.length > 0 ? false:null"
                        :type="password2FieldType"
                        placeholder="Confirm Password"
                        required
                        trim
                        style="height: 52px;"
                      />
                      <b-input-group-append
                        is-text
                        class="cursor-pointer"
                        @click="togglePassword2Visibility"
                        style="border: 1px solid #d8d6de; border-radius: 0px 5px 5px 0px;"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <div class="w-100 mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-continue-addsite float-right"
                  style="border-radius: 50px; height: 46px; width: 148px;"
                  @click.prevent="formSubmitted"
                >
                  Save changes
                </b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar,
  BFormTextarea, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { db, auth } from '@/firebase'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
    BFormTextarea,
    BFormCheckbox,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,

    // Form Wizard
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      profileFile: null,
      password: '',
      passwordConfirm: '',
      email: '',
      usuarioData: {},
      fileImg: '',
      optionsSelected: [
        { text: 'Orange', value: 'orange' },
      ],
      loadingWizard: false,
      optionsCountry: [
        { title: 'United States' },
        { title: 'Canada' },
        { title: 'Mexico' },
        { title: 'Guatemala' },
        { title: 'Belize' },
        { title: 'Honduras' },
        { title: 'El Salvador' },
        { title: 'Nicaragua' },
        { title: 'Costa Rica' },
        { title: 'Panama' },
      ],
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      loadingSubmit: false,
    }
  },
  computed: {
    ...mapState(['cargaAction', 'usuario']),
    passwordToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    profileFile(val) {
      // console.log(val)
      this.fileImg = val
      // this.usuarioData.id = this.usuario.uid
      this.usuarioData.fileImg = this.fileImg
      this.editarGeneralUsuario(this.usuarioData)
      if (val) {
        const reader = new FileReader()
        reader.readAsDataURL(val)
        reader.onload = e => {
          // console.log(e.target.result)
          this.usuarioData.avatar = e.target.result
        }
      } else {
        this.usuarioData.avatar = ''
      }
    },
  },
  methods: {
    ...mapActions(['editarGeneralUsuario', 'cerrarSesion']),
    togglePasswordVisibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if(success) {
            // this.loadingWizard = true
            this.loadingSubmit = true
            this.submitForm = true
            this.setUsuario()
          } else {
            // this.loadingWizard = false
            this.submitForm = false
          }
        })
      })
    },
    setUsuario() {
      const user = auth.currentUser
      if(this.password && this.passwordConfirm) {
        var obj = {id: user.uid, pass: this.password}
        // update seocloud-dcdfb
        var config = {
          method: 'post',
          url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/api/auth/update/usuarios/data',
          maxBodyLength: Infinity,
          timeout: 0,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(obj)
        };

        this.axios(config)
        .then((response) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   position: 'top-right',
          //   props: {
          //     title: `Password changed.`,
          //     icon: 'CoffeeIcon',
          //     variant: 'success',
          //   },
          // })
          this.cerrarSesion()
          this.loadingSubmit = false
          this.submitForm = false
        })
        .catch(e => console.log(e))

        // this.$http.get(`https://us-central1-seocloud-7e24d.cloudfunctions.net/api/auth/update/usuarios/data`)
        // .then(res => {
        //     // console.log(res.data, res.status)
        //     if(res.status === 200) {
        //       this.$toast({
        //         component: ToastificationContent,
        //         position: 'top-right',
        //         props: {
        //             title: `Password changed.`,
        //             icon: 'CoffeeIcon',
        //             variant: 'success',
        //         },
        //       })
        //       this.cerrarSesion()
        //     }
        // })
        // .catch(() => this.loadingSubmit = false)
      }

      if(this.usuarioData.email !== this.email) {
        // update seocloud-dcdfb
        this.$http.get(`https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/api/auth/update/usuarios/email/data/${user.uid}/${this.usuarioData.email}`)
        .then(res => {
          // console.log(res.data, res.status)
          if(res.status === 200) {
            // this.$toast({
            //   component: ToastificationContent,
            //   position: 'top-right',
            //   props: {
            //       title: `Email changed.`,
            //       icon: 'CoffeeIcon',
            //       variant: 'success',
            //   },
            // })
            this.cerrarSesion()
            this.loadingSubmit = false
            this.submitForm = false
          }
        })
        .catch(() => this.loadingSubmit = false)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAdicional() {
      return new Promise((resolve, reject) => {
        this.$refs.adicionalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  created() {
    const user = auth.currentUser

    const ref = db.collection('Users').doc(user.uid)
    ref.onSnapshot(doc => {
      this.usuarioData = {}
      this.usuarioData = {
        id: doc.data().uid,
        email: doc.data().email,
        // firstName: doc.data().firstName ? doc.data().firstName : doc.data().firstName,
        // lastName: doc.data().lastName ? doc.data().lastName : '',
        // email: doc.data().email,
        // role: doc.data().role,
        // avatar: doc.data().foto,
        // contact: doc.data().contact
      }
      this.email = doc.data().email
    })

    // var obj = {
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // };

    // var data = JSON.stringify({
    //   "id": "JvoMs1sb71QmDSUd0jGViDi9kfQ2",
    //   "password": "User@123"
    // });
    // this.$http.get(`https://us-central1-seocloud-7e24d.cloudfunctions.net/app/api/auth/update/usuarios/data/${obj.id}/${obj.password}`)
    // .then(res => {
    //     console.log(res.data, res.status)
    // })
    // https://us-central1-nycpg-69e96.cloudfunctions.net/updateAuthUsers

    // var config = {
    //   method: 'post',
    //   url: 'https://us-central1-seocloud-7e24d.cloudfunctions.net/app/api/auth/update/usuarios/data',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    //   data : data
    // };

    // this.axios(config)
    // .then(res => {
    //     console.log(res.data, res.status)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
    // .catch(e => {
    //     console.log(e)
    //     // this.password = ''
    //     // this.passwordConfirm = ''
    // })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

<style>
/* This is a css loader. It's not related to vue-form-wizard */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  /* margin: 60px auto; */
  font-size: 2px;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #e74c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.vs__dropdown-toggle {
  background: #fff !important;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.title-tab {
  font-size: 24px;
  width: 700px;
}
</style>
